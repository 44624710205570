<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :width="700"
      :ok-button-props="{ props: { loading: submitting } }"
      title="发送讣告"
      @ok="handleSubmit"
    >
      <span>请确认并维护以下信息：</span>
      <a-form
        class="custom-compact-form"
        style="margin-top:20px;"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="逝者姓名">
          <a-input
            :disabled="true"
            v-decorator="['deadName', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="逝世日期">
          <a-input
            :disabled="true"
            v-decorator="['deadTime', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="年龄">
          <a-input
            :disabled="true"
            v-decorator="['age', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-input
            :disabled="true"
            v-decorator="['gender', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="发布人">
          <a-input
            v-decorator="['master_name', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="发布日期">
          <a-date-picker
            style="width: 100%;"
            v-decorator="['make_at', {
              rules: [
                { required: true, message: `请选择发布日期` }
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="抬头">
          <a-input
            v-decorator="['title', {
              normalize: this.$lodash.trim
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="讣告内容">
          <a-textarea
            v-decorator="['content', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 1000, message: '最多1000个字符' },
                { required: true, message: '请输入讣告内容' }
              ]
            }]"
          />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button class="ant-btn-default" @click="closeModal">
          关闭
        </a-button>
        <a-button class="ant-btn-primary" @click="handleSubmit" :loading="submitting">
          生成图片
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>

import { makeObituary, findObituaryInfo } from '@/api/order'
import { formatDate } from '@/utils/time'

export default {
  name: 'SendObituary',
  components: {
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'send_obituary' }),
      submitting: false,
      data: {}
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      findObituaryInfo(this.serviceOrderId, {}).then((res) => {
        this.form.setFieldsValue({
          deadName: res.data.deadName,
          deadTime: res.data.deadTime,
          age: res.data.age,
          gender: res.data.gender,
          master_name: res.data.releasePerson,
          make_at: this.$moment(res.data.releaseTime),
          title: res.data.title,
          content: res.data.content
        })
      })
    },

    closeModal() {
      this.isShow = false
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.$info({
        title: '正在生成讣告图片，请耐心等候',
        content: ''
      })
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          values.make_at = formatDate(values.make_at)
          makeObituary(this.serviceOrderId, values).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
