var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"width":700,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"发送讣告"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('span',[_vm._v("请确认并维护以下信息：")]),_c('a-form',{staticClass:"custom-compact-form",staticStyle:{"margin-top":"20px"},attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"逝者姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['deadName', {
            normalize: this.$lodash.trim
          }]),expression:"['deadName', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"逝世日期"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['deadTime', {
            normalize: this.$lodash.trim
          }]),expression:"['deadTime', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"年龄"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['age', {
            normalize: this.$lodash.trim
          }]),expression:"['age', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['gender', {
            normalize: this.$lodash.trim
          }]),expression:"['gender', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"disabled":true,"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"发布人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['master_name', {
            normalize: this.$lodash.trim
          }]),expression:"['master_name', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"发布日期"}},[_c('a-date-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['make_at', {
            rules: [
              { required: true, message: "请选择发布日期" }
            ]
          }]),expression:"['make_at', {\n            rules: [\n              { required: true, message: `请选择发布日期` }\n            ]\n          }]"}],staticStyle:{"width":"100%"}})],1),_c('a-form-item',{attrs:{"label":"抬头"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['title', {
            normalize: this.$lodash.trim
          }]),expression:"['title', {\n            normalize: this.$lodash.trim\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"讣告内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['content', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 1000, message: '最多1000个字符' },
              { required: true, message: '请输入讣告内容' }
            ]
          }]),expression:"['content', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 1000, message: '最多1000个字符' },\n              { required: true, message: '请输入讣告内容' }\n            ]\n          }]"}]})],1)],1),_c('template',{slot:"footer"},[_c('a-button',{staticClass:"ant-btn-default",on:{"click":_vm.closeModal}},[_vm._v(" 关闭 ")]),_c('a-button',{staticClass:"ant-btn-primary",attrs:{"loading":_vm.submitting},on:{"click":_vm.handleSubmit}},[_vm._v(" 生成图片 ")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }